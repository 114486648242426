<template>
  <div>
  </div>
</template>
<script>
import { mapState  } from "vuex";
export default ({
  name: 'Qrcode',
  data() {
    return{
      
    }
  },
  computed: {
    ...mapState('common', {
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
    })
  },
  created(){
    if (/android/i.test(navigator.userAgent)) {
      console.log('Android');//这是Android平台下浏览器
      window.location.href = this.androidDownloadUrl;
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('IOS');//这是iOS平台下浏览器
      window.location.href = this.iosMt4DownloadUrl;
    }
    this.$emit('controlShow', true);
  },
  methods:{

  }
})
</script>

